
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/kennel/[kennelId]",
      function () {
        return require("private-next-pages/kennel/[kennelId]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/kennel/[kennelId]"])
      });
    }
  